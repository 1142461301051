/* Required height of parents of the Full Page Intro and Intro itself */
.timer-box {
  background: rgba(10, 10, 10, 0.9);
  color: #fff;
  border-radius: 0.4em;
  font-family: "Barlow", sans-serif;
  align-content: center;
}

.timer-box-num {
  font-size: 30pt;
  text-align: center;
}

.timer-box-text {
  font-size: 10pt;
  text-align: center;
}

.content {
  font-family: "Barlow", sans-serif;
}

span.h4 {
  font-family: "Barlow", sans-serif;
}

i {
  color: black;
}

a > i.social-icon:hover {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.35);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

#notifs-forn-input,
#notifs-form-input:focus {
  border: solid 2px #ccc;
}
